import React from 'react'

const Triangle = (props: any) => (
  <svg viewBox="0 0 1024 1024" version="1.1" p-id="1644" width="200" height="200" {...props}>
    <path
      d="M934.4 942.08H89.6c-17.152 0-32.853333-9.386667-40.618667-23.466667a49.877333 49.877333 0 0 1 0-46.933333l422.4-732.16c7.808-14.08 23.466667-23.466667 40.661334-23.466667 17.194667 0 32.853333 9.386667 40.661333 23.466667l422.4 732.16c7.850667 14.08 7.850667 32.853333 0 46.933333-7.808 14.08-23.466667 23.466667-40.661333 23.466667z m-763.392-93.866667h682.069333L512.042667 256.853333 171.008 848.213333z"
      p-id="1645" fill="#ffffff"></path>
  </svg>
)

export default Triangle
